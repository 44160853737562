import styled from 'styled-components'

export const Hero = styled.section`
  position: relative;
  padding: 5rem 0 0;

  ${({ theme }) => theme.mq.tablet} {
    padding: 8rem 0 0;
  }
`

export const Wrapper = styled.div`
  max-width: 108rem;
  margin: 0 auto;
  padding: 0 3.7rem;
  color: ${({ theme }) => theme.darkgray};
  text-align: right;

  ${({ theme }) => theme.mq.tablet} {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 70vh;
    max-height: 80rem;
  }
`

export const ImageContainer = styled.div`
  position: relative;
  width: calc(100% + 2 * 3.7rem);
  height: 40vh;
  margin-left: -3.7rem;
  object-fit: cover;

  div {
    width: 100%;
    height: 100%;
  }

  ${({ theme }) => theme.mq.tablet} {
    position: absolute;
    top: 0;
    left: 0;
    width: 50%;
    height: 100%;
  }
`

export const ContentContainer = styled.div``

export const Header = styled.h1`
  margin: 3rem 0 4rem;
  color: ${({ theme }) => theme.red};
  font-weight: ${({ theme }) => theme.font.weight.bold};
  font-size: ${({ theme }) => theme.font.size.headers.l};
  text-transform: capitalize;

  ${({ theme }) => theme.mq.tablet} {
    font-size: ${({ theme }) => theme.font.size.headers.xl};
  }

  ${({ theme }) => theme.mq.bigTablet} {
    font-size: ${({ theme }) => theme.font.size.headers.xxl};
  }
`

export const HeaderTwo = styled.h2`
  max-width: 28rem;
  margin-bottom: 3.2rem;
  margin-left: auto;
  font-weight: ${({ theme }) => theme.font.weight.bold};
  font-size: ${({ theme }) => theme.font.size.headers.m};

  ${({ theme }) => theme.mq.bigTablet} {
    max-width: 42rem;
    font-size: ${({ theme }) => theme.font.size.headers.l};
  }
`

export const Paragraph = styled.p`
  margin-left: auto;
`
