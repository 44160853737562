import React from 'react'

import * as S from './guaranteeAndPassion.styles'

interface GuaranteeAndPassionProps {}

const GuaranteeAndPassion: React.FC<GuaranteeAndPassionProps> = () => (
  <S.GuaranteeAndPassion>
    <S.Wrapper>
      <S.Contnet>
        <S.Header>Gwarancja</S.Header>
        <S.Paragraph>
          Na życzenie klienta, każdy sprzedawany u nas samochód może zostać objęty gwarancją w zewnętrznej firmie. Do
          wyboru pakiety: 1 miesiąc, 3 miesiące, 6 miesięcy, 12 miesięcy lub 24 miesiące. W zależoności od wybranego
          pakietu gwarancja obejmuje: zawieszenie, paski rozrządu, wały napędowe, klimatyzacja, turbo sprężarka,
          mechanizm różnicowy, silnik, alternator, skrzynie biegów, rozrusznik, komputer główny, obudowy, koło zamachowe
          dwumasowe, układ hamulcowy, układ chłodzenia oraz łańcuch rozrządu. Szczegółowe informacje na temat gwarancji
          uzyskacie Państwo w siedzibie naszej firmy.
        </S.Paragraph>
      </S.Contnet>
      <S.Contnet>
        <S.Header>Pasja</S.Header>
        <S.Paragraph>
          Najważniesze jest żeby robić to co się lubi dlatego czerpiemy przyjemność z prowadzenia naszej działalności.
          Łączymy biznes z hobby dzięki czemu uśmiech towarzyszy nam i naszym klientom po dokonaniu właściwych wyborów.
          Angażujemy się w 100% aby sprostać wysoko postawionej poprzeczce. Samochody to nasza pasja a zadowolenie
          klientów to priorytet.
        </S.Paragraph>
      </S.Contnet>
    </S.Wrapper>
  </S.GuaranteeAndPassion>
)

export default GuaranteeAndPassion
