import styled from 'styled-components'

export const GuaranteeAndPassion = styled.section`
  position: relative;
  z-index: -2;
  padding: 6rem 0;
  background: ${({ theme }) => theme.lightgray};
`

export const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 108rem;
  margin: 0 auto;
  padding: 0 3.7rem;
  color: ${({ theme }) => theme.darkgray};

  ${({ theme }) => theme.mq.tablet} {
    flex-direction: row;
  }
`

export const Contnet = styled.div`
  flex-basis: 48%;
  margin-bottom: 7rem;

  :last-child {
    margin-bottom: 0;
  }

  ${({ theme }) => theme.mq.tablet} {
    margin-bottom: 0;
  }
`

export const Header = styled.h2`
  position: relative;
  padding-left: 0.5rem;
  font-weight: ${({ theme }) => theme.font.weight.semibold};
  font-size: ${({ theme }) => theme.font.size.headers.l};

  ::after {
    position: absolute;
    top: 3.8rem;
    left: 0;
    width: 0.5rem;
    height: 10rem;
    background: ${({ theme }) => theme.red};
    content: '';
  }
`

export const Paragraph = styled.p`
  margin: 3rem 0 0;
  padding-left: 3rem;

  ${({ theme }) => theme.mq.tablet} {
    margin-bottom: 0;
  }
`
