import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'
import Image from 'gatsby-image'

import * as S from './hero.styles'

interface HeroProps {}

const Hero: React.FC<HeroProps> = () => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "desktopAboutHeroBg.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <S.Hero>
      <S.Wrapper>
        <S.ImageContainer>
          <Image fluid={data.file.childImageSharp.fluid} style={{ position: 'absolute' }} />
        </S.ImageContainer>
        <S.ContentContainer>
          <S.Header>
            JM
            <br />
            car company
          </S.Header>
          <S.HeaderTwo>Dealer samochodowy, któremu możesz zaufać.</S.HeaderTwo>
          <S.Paragraph>Robimy to co lubimy, łączymy biznes z pasją.</S.Paragraph>
        </S.ContentContainer>
      </S.Wrapper>
    </S.Hero>
  )
}

export default Hero
