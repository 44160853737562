import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import Image from 'gatsby-image'

import * as S from './eagles.styles'

interface EaglesProps {}

const Eagles: React.FC<EaglesProps> = () => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "eagles.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <S.Eagles>
      <S.Wrapper>
        <S.ContentContainer>
          <S.Header>Doświadczenie</S.Header>
          <S.Paragraph>
            Początki naszej przygody z motoryzacją sięgają czasów Fiata 125p oraz Poloneza. Przez wszystkie lata
            zdobywaliśmy doświadczenie na wielu markach, modelach oraz własnej skórze. Na rynku istniejemy od 20 lat i
            posiadamy spory wachlarz kompetencji dzięki czemu możemy doradzić Naszym klientom lub pomóc w podjęciu
            odpowiedniej decyzji. Czy to wszystko? Nie. Z każdym rokiem i nowymi modelami poszerzamy swoje horyzonty aby
            być na bieżąco z motoryzacyjnymi nowościami. Wychodzimy z założenia, że zawsze należy się rozwijać i dążyć
            do perfekcji co staramy się robić każdego dnia naszej działalności. Nasz trud i zaangażowanie zostały
            docenione przez naszych klientów co pokazują opinie w sieci. Dzięki temu zostaliśmy również ZWYCIĘZCĄ oraz
            laureatem plebiscytu branżowego w kategorii motoryzacja - ORŁY HANDLU 2020. Dziękujemy wszystkim Naszym
            klientom za wyrażanie swoich opinii na temat naszej firmy. Jesteśmy dumni, że nasz trud został doceniony.
            Jest to jednocześnie motywacja do pójścia o krok dalej i zawalczenia o kolejne tytuły w przyszłości.
          </S.Paragraph>
        </S.ContentContainer>
        <S.ImageContainer>
          <Image fluid={data.file.childImageSharp.fluid} style={{ position: 'absolute' }} />
        </S.ImageContainer>
      </S.Wrapper>
    </S.Eagles>
  )
}

export default Eagles
