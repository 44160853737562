import styled, { css } from 'styled-components'

export const QualityAndDetail = styled.section`
  position: relative;
`

export const Wrapper = styled.div`
  max-width: 108rem;
  margin: 0 auto;
  padding: 0 3.7rem;
  color: ${({ theme }) => theme.darkgray};

  ${({ theme }) => theme.mq.tablet} {
    display: flex;
  }
`

export const Header = styled.h2<{ color?: string }>`
  position: relative;
  padding-left: 0.5rem;
  font-weight: ${({ theme }) => theme.font.weight.semibold};
  font-size: ${({ theme }) => theme.font.size.headers.l};

  ::after {
    position: absolute;
    top: 3.8rem;
    left: 0;
    width: 0.5rem;
    height: 10rem;
    background: ${({ theme }) => theme.red};
    content: '';

    ${({ color, theme }) =>
      color === 'white' &&
      css`
        background: ${theme.white};
      `}
  }
`

export const Content = styled.div`
  position: relative;
  padding: 9rem 0;

  :last-child {
    width: calc(100% + 2 * 3.7rem);
    margin-left: -3.7rem;
    padding: 9rem 3.7rem;
    color: ${({ theme }) => theme.white};
  }

  ${({ theme }) => theme.mq.tablet} {
    position: initial;
    flex-basis: 40%;

    padding: 9rem 4.5rem 4rem 0;

    :first-child {
      position: relative;

      ::after {
        position: absolute;
        top: 0;
        left: 100%;
        width: 100%;
        height: 100%;
        background: ${({ theme }) => theme.transparentRed};
        content: '';
      }
    }

    :last-child {
      width: auto;
      margin-left: 0;
      padding-right: 3.7rem;
    }
  }
`

export const Paragraph = styled.p`
  position: relative;
  margin: 3rem 0 0;
  padding-left: 3rem;

  ${({ theme }) => theme.mq.tablet} {
    margin-bottom: 0;
  }
`

export const ImageContainer = styled.div`
  div {
    top: 0rem;
    left: 0;
    width: 100%;
    height: 100%;
  }

  ${({ theme }) => theme.mq.tablet} {
    div {
      top: initial;
      right: 0;
      bottom: 0;
      left: initial;
      z-index: -1;
      width: 45%;
      height: 55rem;
    }
  }
`

export const Mask = styled.div`
  position: absolute;
  top: 0rem;
  left: 0;
  width: 100%;
  height: 100%;
  background: ${({ theme }) => theme.transparentRed};

  ${({ theme }) => theme.mq.tablet} {
    display: none;
  }
`
