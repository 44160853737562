import React from 'react'

import * as S from './numbers.styles'

interface NumbersProps {}

const Numbers: React.FC<NumbersProps> = () => (
  <S.Numbers>
    <S.Wrapper>
      <S.Number>
        20
        <span>lat&nbsp;na&nbsp;rynku</span>
      </S.Number>
      <S.Number>
        5.0
        <span>ocena&nbsp;klientów</span>
      </S.Number>
    </S.Wrapper>
  </S.Numbers>
)

export default Numbers
