import styled from 'styled-components'

export const Numbers = styled.section`
  margin-top: 4rem;
  color: ${({ theme }) => theme.darkgray};
  background: ${({ theme }) => theme.lightgray};

  ${({ theme }) => theme.mq.tablet} {
    margin-top: 0;
  }
`

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 40rem;
  margin: 0 auto;
  padding: 4rem 3.7rem 11.5rem;

  ${({ theme }) => theme.mq.tablet} {
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-around;
    max-width: 100rem;
  }
`

export const Number = styled.p`
  position: relative;
  display: block;
  margin-bottom: 14.5rem;
  font-weight: ${({ theme }) => theme.font.weight.semibold};
  font-size: ${({ theme }) => theme.font.size.body.xxl};

  ::after {
    position: absolute;
    bottom: -1rem;
    left: 0;
    width: 14rem;
    height: 1.6rem;
    background: ${({ theme }) => theme.red};
    content: '';
  }

  :last-child {
    align-self: flex-end;
    margin-bottom: 0;
  }

  span {
    position: absolute;
    right: 0;
    bottom: -6rem;
    font-size: ${({ theme }) => theme.font.size.body.l};
  }

  ${({ theme }) => theme.mq.tablet} {
    margin-bottom: 0;

    :last-child {
      align-self: initial;
    }

    span {
      left: 25%;
    }
  }

  ${({ theme }) => theme.mq.bigTablet} {
    span {
      left: 50%;
    }
  }
`
