import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import Image from 'gatsby-image'

import * as S from './qualityAndDetail.styles'

interface QualityAndDetailProps {}

const QualityAndDetail: React.FC<QualityAndDetailProps> = () => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "qualityAndDetail.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <S.QualityAndDetail>
      <S.Wrapper>
        <S.Content>
          <S.Header>Jakość</S.Header>
          <S.Paragraph>
            Wysoka jakość towaru i świadczonej obsługi to nasze drugie imię. Niski przebieg, pełna dokumentacja
            serwisowa, bogate wyposażenie, świetny stan pojazdów oraz unikatowe i limitowane edycje to coś co wyróżnia
            nas na tle konkurencji. Swoją pozycję na rynku bazujemy na zaufaniu oraz długotrwałej relacji z naszymi
            klientami. Do każdego podchodzimy indywidualnie.
          </S.Paragraph>
        </S.Content>
        <S.Content>
          <S.ImageContainer>
            <Image fluid={data.file.childImageSharp.fluid} style={{ position: 'absolute' }} />
          </S.ImageContainer>
          <S.Mask />
          <S.Header color='white'>Szczegółowość</S.Header>
          <S.Paragraph>
            Diabeł tkwi w szczegółach. My o tym wiemy dlatego przykładamy wagę do drobiazgów. Każdy oferowany przez nas
            samochód jest dokładnie sprawdzany pod kątem technicznym i wizualnym. Nie ma mowy o niedociągnięciach w
            dokumentacji.
          </S.Paragraph>
        </S.Content>
      </S.Wrapper>
    </S.QualityAndDetail>
  )
}

export default QualityAndDetail
