import styled from 'styled-components'

export const Eagles = styled.section`
  position: relative;
  padding: 7rem 3.7rem 0;

  ${({ theme }) => theme.mq.tablet} {
    padding: 9.7rem 0 9.7rem 0;
  }
`

export const Wrapper = styled.div`
  max-width: 108rem;
  margin: 0 auto;
  color: ${({ theme }) => theme.darkgray};

  a {
    margin: 0 auto;
  }

  ${({ theme }) => theme.mq.tablet} {
    padding: 0 3.7rem;
  }
`
export const ContentContainer = styled.div`
  ${({ theme }) => theme.mq.tablet} {
    max-width: 50%;
  }
`

export const Header = styled.h2`
  position: relative;
  padding-left: 0.5rem;
  font-weight: ${({ theme }) => theme.font.weight.semibold};
  font-size: ${({ theme }) => theme.font.size.headers.l};

  ::after {
    position: absolute;
    top: 3.8rem;
    left: 0;
    width: 0.5rem;
    height: 10rem;
    background: ${({ theme }) => theme.red};
    content: '';
  }
`

export const Paragraph = styled.p`
  margin: 3rem 0 4rem;
  padding-left: 3rem;

  ${({ theme }) => theme.mq.tablet} {
    margin-bottom: 0;
  }
`

export const ImageContainer = styled.div`
  position: relative;
  width: 100%;
  max-width: 36rem;
  height: 36rem;
  margin: 0 auto;
  object-fit: cover;

  div {
    width: calc(100% + 2 * 3.7rem);
    height: 100%;
    margin-left: -3.7rem;
  }

  ${({ theme }) => theme.mq.tablet} {
    position: absolute;
    top: 50%;
    right: 0;
    width: 45%;
    max-width: initial;
    height: 100%;
    transform: translateY(-50%);

    div {
      width: 100%;
      margin-left: 0;
    }
  }
`
