import React from 'react'

import Layout from 'src/components/layout/layout'
import Seo from 'src/components/seo'
import Hero from 'src/components/aboutSections/hero'
import Numbers from 'src/components/aboutSections/numbers'
import Eagles from 'src/components/aboutSections/eagles'
import GuaranteeAndPassion from 'src/components/aboutSections/guaranteeAndPassion'
import QualityAndDetail from 'src/components/aboutSections/qualityAndDetail'

interface AboutPageProps {}

const AboutPage: React.FC<AboutPageProps> = () => (
  <Layout>
    <Seo
      title='Dealer samochodowy, któremu możesz zaufać - JM Car Company'
      description='Na rynku istniejemy od 20 lat i wciąż się rozwijamy. Robimy to co lubimy, łączymy biznes z pasją.'
    />
    <Hero />
    <Numbers />
    <Eagles />
    <GuaranteeAndPassion />
    <QualityAndDetail />
  </Layout>
)

export default AboutPage
